<template>
  <div class="news pa-4 pa-sm-6 pa-md-8">
    <h1 class="font-weight-bold white--text mb-4 mb-sm-6">News and Updates</h1>
    <div v-if="loading">
      <v-skeleton-loader
        type="article"
        class="news-card mb-5"
      ></v-skeleton-loader>
      <v-skeleton-loader
        type="article"
        class="news-card mb-5"
      ></v-skeleton-loader>
      <v-skeleton-loader
        type="article"
        class="news-card mb-5"
      ></v-skeleton-loader>
    </div>
    <div class="bs-row bs-g-3" v-else>
      <div v-for="(item, index) in records" :key="index">
        <v-card
          class="news-card"
          v-ripple
          @click="viewNewsAndUpdates(item)"
          style="cursor: pointer"
        >
          <div class="pa-4 pa-sm-6">
            <div
              class="d-flex flex-column-reverse flex-lg-row justify-space-between bs-gap-3 bs-gap-md-4"
            >
              <div>
                <div class="d-flex align-center bs-gap-2 mb-3">
                  <v-icon small color="primary"
                    >mdi-calendar-blank-outline</v-icon
                  >
                  <div class="text-uppercase primary--text">
                    {{ formateDate(item.created_at) }}
                  </div>
                </div>
                <h2 class="font-weight-bold mb-3">{{ item.title }}</h2>
                <div class="mb-4">{{ item.game }}</div>
                <div
                  class="d-flex flex-column flex-md-row align-md-center bs-gap-3 mb-6"
                >
                  <div class="d-flex flex-wrap bs-gap-2">
                    <v-chip
                      v-for="(hash, hashIndex) in item.hashtags"
                      :key="hashIndex"
                      outlined
                      color="grey"
                      small
                      style="padding-inline: 0.75rem !important"
                    >
                      #{{ hash }}
                    </v-chip>
                  </div>
                </div>
                <p
                  class="grey--text mb-0"
                  style="
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                    white-space: pre-line;
                  "
                >
                  {{ item.description }}
                </p>
              </div>
              <div class="d-inline-flex">
                <img :src="item.image" class="d-block" />
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>

    <v-dialog
      v-model="newsAndUpdatesDialog"
      v-if="newsAndUpdatesDetails"
      max-width="500"
      scrollable
    >
      <v-card
        class="elevation-0"
        style="
          position: relative;
          box-shadow: 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.35) !important;
          border: 5px solid #ffc82d !important;
          border-radius: 1rem !important;
        "
      >
        <div style="position: absolute; top: 1rem; right: 1rem; z-index: 5">
          <v-btn
            class="float-end"
            text
            icon
            @click="newsAndUpdatesDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <img
          :src="newsAndUpdatesDetails.cover_image"
          class="d-block"
          style="width: 100%; height: auto; border-radius: 0.5rem !important"
        />
        <div
          class="news-dialog-content pa-4 pa-sm-6 pa-md-8"
          style="overflow-y: auto"
        >
          <div class="d-flex align-center bs-gap-2 mb-3">
            <v-icon small color="primary">mdi-calendar-blank-outline</v-icon>
            <div class="text-uppercase primary--text">
              {{ formateDate(newsAndUpdatesDetails.created_at) }}
            </div>
          </div>
          <h2 class="font-weight-bold mb-3">
            {{ newsAndUpdatesDetails.title }}
          </h2>
          <div class="mb-4">{{ newsAndUpdatesDetails.game }}</div>
          <div
            class="d-flex flex-column flex-md-row align-md-center bs-gap-3 mb-6"
          >
            <div class="d-flex flex-wrap bs-gap-2">
              <v-chip
                v-for="(hash, hashIndex) in newsAndUpdatesDetails.hashtags"
                :key="hashIndex"
                outlined
                color="grey"
                small
                style="padding-inline: 0.75rem !important"
              >
                #{{ hash }}
              </v-chip>
            </div>
          </div>
          <p class="grey--text mb-0" style="white-space: pre-line">
            {{ newsAndUpdatesDetails.description }}
          </p>
          <v-btn
            block
            class="light mt-4 mt-sm-6"
            depressed
            @click="newsAndUpdatesDialog = false"
            >Close</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import formatDate from "@/helpers/formatDate";
import responseGet from "@/helpers/api_request_get";
import { mapActions } from "vuex";

export default {
  data: () => ({
    loading: false,
    records: [],
    newsAndUpdatesDialog: false,
    newsAndUpdatesDetails: null,
  }),

  created() {
    this.loadRecords();
  },

  methods: {
    ...mapActions("logout", ["doLogout"]),

    formateDate(date) {
      return formatDate(date, "format", "MMMM dd, Y");
    },

    async loadRecords() {
      if (!this.loading) {
        this.loading = true;

        const response = await responseGet("", "news_and_updates");

        switch (response.status) {
          case 200:
            this.records = response.data;
            break;
          case 401:
            await this.doLogout();
            this.$router.push({ name: "Home" });
            break;
        }

        this.loading = false;
      }
    },

    viewNewsAndUpdates(details) {
      this.newsAndUpdatesDetails = details;
      this.newsAndUpdatesDialog = true;

      this.$nextTick(() => {
        let cardContent = document.querySelector(".news-dialog-content");
        if (cardContent.scrollTop > 0) {
          cardContent.scrollTo({
            top: 0,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.news {
  background: url("../../assets/images/news_and_updates/background.jpg")
    no-repeat center;
  background-size: cover;
}

.news .news-card {
  box-shadow: 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.35) !important;
  border: 5px solid #ffc82d !important;
  border-radius: 1rem !important;
}

.news .news-card img {
  width: 220px;
  height: 220px;
  border-radius: 0.5rem !important;
}

@media (width < 1264px) {
  .news .news-card img {
    width: auto;
    height: 92.5px;
  }
}

@media (width < 768px) {
  .news .news-card h2 {
    font-size: 18px !important;
  }
}
</style>
